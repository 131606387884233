.w-calendar-inner {
  text-align: right;
  padding: 5px;
}
.w-calendar-panel {
  width: 100%;
  height: 90px;
  line-height: 16px;
  overflow-y: auto;
  text-align: left;
}
.w-calendar-panel > * {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.w-calendar-caption {
  font-size: 26px;
  font-weight: 200;
  width: 100%;
  display: inline-block;
}
.w-calendar-title,
.w-calendar-btn-group {
  display: inline-block;
  -webkit-user-select: none;
          user-select: none;
}
.w-calendar-btn-group {
  float: right;
}
.w-calendar-btn-group > .w-calendar-btn {
  font-size: 16px;
  padding: 2px 3px;
  position: relative;
  top: -3px;
}
.w-calendar-btn-group > .w-icon,
.w-calendar-btn-group > .w-calendar-btn {
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
}
.w-calendar-btn-group > .w-icon:hover,
.w-calendar-btn-group > .w-calendar-btn:hover {
  background-color: #ececec;
}
.w-calendar-btn-group > .w-icon:active,
.w-calendar-btn-group > .w-calendar-btn:active {
  background-color: #dedddd;
}
.w-calendar-btn-group > .w-icon,
.w-calendar-btn-group > .w-calendar-btn {
  vertical-align: middle;
  margin-top: -6px;
}
.w-calendar-btn-group > .w-icon > svg,
.w-calendar-btn-group > .w-calendar-btn > svg {
  width: 18px;
  height: 18px;
  margin: 4px 0px 0px 4px;
}
.w-calendar-btn-group > .w-icon:last-child,
.w-calendar-btn-group > .w-calendar-btn:last-child {
  transform: rotate(-90deg);
}
.w-calendar-btn-group > .w-icon:first-child,
.w-calendar-btn-group > .w-calendar-btn:first-child {
  transform: rotate(90deg);
}
.w-calendar-day {
  padding-right: 5px;
}
.w-calendar .w-datepicker-body {
  padding: 0 0 0 0;
}
.w-calendar .w-datepicker-week:last-child > div {
  border-bottom: 0;
}
.w-calendar .w-datepicker-week > div {
  border-radius: 0;
  border-right: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}
.w-calendar .w-datepicker-week > div:last-child {
  border-right: 0;
}
.w-calendar .w-datepicker-week > div > div {
  margin: 0;
  border-radius: 0;
}
.w-calendar .w-datepicker-weekday > div {
  background-color: #ececec;
  height: 32px;
  line-height: 32px;
}
