.w-breadcrumb-item {
  display: inline-flex;
}
.w-breadcrumb-item + .w-breadcrumb-item::before,
.w-breadcrumb-item + .w-breadcrumb-item .w-breadcrumb-separator {
  padding-right: 8px;
  padding-left: 8px;
  color: #6e6e6e;
}
.w-breadcrumb-item + .w-breadcrumb-item::before {
  display: inline-block;
  content: attr(data-separator);
}
.w-breadcrumb-item + .w-breadcrumb-item.no-before:before {
  display: none;
}
.w-breadcrumb-item + .w-breadcrumb-item.no-separator {
  margin-left: 6px;
}
.w-breadcrumb-item + .w-breadcrumb-item.no-separator:before {
  display: none;
}
.w-breadcrumb-item.active {
  color: #6e6e6e;
}
