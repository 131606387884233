.w-alert .w-modal-header,
.w-alert .w-modal-body {
  display: table-cell;
}
.w-alert .w-modal-header {
  background-color: transparent;
  padding: 20px 0 0 20px;
  box-shadow: 0 0 0 0;
  padding-right: 0;
}
.w-alert .w-modal-header > .w-icon {
  font-size: 40px;
  margin-right: 0;
}
.w-alert .w-modal-body {
  padding-right: 20px;
  padding-left: 20px;
  vertical-align: top;
}
.w-alert.primary .w-modal-header > .w-icon {
  color: #008ef0;
}
.w-alert.success .w-modal-header > .w-icon {
  color: #28a745;
}
.w-alert.warning .w-modal-header > .w-icon {
  color: #ffc107;
}
.w-alert.danger .w-modal-header > .w-icon {
  color: #dc3545;
}
.w-alert .w-modal-inner .w-modal-header > button {
  min-width: 18px;
  min-height: 18px;
  padding: 5px 5px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.w-alert .w-modal-shown-title.w-modal-inner .w-modal-header {
  word-break: break-word;
  display: flex;
  padding-top: 15px;
  padding-right: 10px;
}
.w-alert .w-modal-shown-title.w-modal-inner .w-modal-header > .w-icon {
  font-size: 40px;
  position: absolute;
  top: 18px;
}
.w-alert .w-modal-shown-title.w-modal-inner .w-modal-header > button > .w-icon {
  font-size: 14px;
}
.w-alert .w-modal-shown-title.w-modal-inner .w-modal-body {
  word-break: break-word;
  padding-top: 5px;
}
.w-alert .w-modal-shown-icon .w-modal-header > h4 {
  padding-left: 60px;
}
.w-alert .w-modal-shown-title.w-modal-shown-icon .w-modal-body {
  padding-left: 80px;
}
