.w-switch {
  vertical-align: middle;
  font-size: 12px;
  cursor: pointer;
}
.w-switch input[type='checkbox'] {
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  min-width: 35px;
  height: 20px;
  margin: 0 !important;
  background-clip: border-box;
  background-color: #dfdfdf;
  -webkit-appearance: none;
          appearance: none;
  outline: 0;
  font-size: 12px;
  border-radius: 16px;
  transition: all 0.3s;
  box-shadow: inset 0 1px 2px rgba(16, 22, 26, 0.2);
}
.w-switch input[type='checkbox']:before,
.w-switch input[type='checkbox']:after {
  display: inline-block;
  vertical-align: middle;
}
.w-switch input[type='checkbox']:before {
  content: attr(data-checked);
  transition: all 0.3s;
  padding: 0 21px 0 6px;
  color: #fff;
  line-height: 20px;
}
.w-switch input[type='checkbox']:checked {
  background-color: #008ef0;
}
.w-switch input[type='checkbox']:checked:after {
  right: 2px;
  margin-right: 0;
}
.w-switch input[type='checkbox']:checked:before {
  content: attr(data-unchecked);
}
.w-switch input[type='checkbox']:not(:checked):before {
  padding: 0 6px 0 21px;
  color: #393e48;
}
.w-switch input[type='checkbox']:after {
  content: ' ';
  position: absolute;
  margin-right: -18px;
  right: 100%;
  margin-top: 2px;
  margin-left: 1px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  transition: all 0.2s;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 35, 11, 0.2);
}
.w-switch input[type='checkbox']:disabled {
  cursor: not-allowed;
  opacity: 0.45;
}
.w-switch input[type='checkbox']:focus {
  box-shadow: 0 0 0 2px rgba(0, 142, 240, 0.25), inset 0 1px 2px rgba(16, 22, 26, 0.2);
}
.w-switch.disabled {
  cursor: not-allowed;
}
.w-switch-large input[type='checkbox'] {
  min-width: 38px;
  height: 24px;
}
.w-switch-large input[type='checkbox']::after {
  margin-right: -22px;
  height: 20px;
  width: 20px;
}
.w-switch-large input[type='checkbox']:before {
  padding: 0 25px 0 8px;
  margin-top: 0;
  line-height: 24px;
  font-size: 16px;
}
.w-switch-large input[type='checkbox']:not(:checked):before {
  padding: 0 8px 0 25px;
}
.w-switch-small {
  font-size: 12px;
}
.w-switch-small input[type='checkbox'] {
  min-width: 15px;
  height: 16px;
}
.w-switch-small input[type='checkbox']::after {
  margin-right: -14px;
  height: 12px;
  width: 12px;
}
.w-switch-small input[type='checkbox']:before {
  padding: 0 16px 0 6px;
  line-height: 16px;
}
.w-switch-small input[type='checkbox']:not(:checked):before {
  padding: 0 6px 0 16px;
}
.w-switch-text,
.w-switch input[type='checkbox'] {
  vertical-align: middle;
}
.w-switch-text {
  display: inline-block;
  margin-left: 5px;
}
