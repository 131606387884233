.w-card {
  background: #fff;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.5;
  position: relative;
  transition: all 0.3s;
}
.w-card:not(.w-card-no-hover):hover,
.w-card.active {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.2);
}
.w-card-bordered {
  border: 1px solid #e9e9e9;
}
.w-card-head {
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  border-radius: 2px 2px 0 0;
  zoom: 1;
}
.w-card-head + .w-card-body {
  border-top: 1px solid #e9e9e9;
}
.w-card-head-title {
  font-size: 14px;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  display: inline-block;
}
.w-card-extra {
  position: absolute;
  right: 16px;
  top: 0;
}
.w-card-body {
  padding: 14px;
}
.w-card-body + .w-card-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.w-card-footer {
  padding: 8px 10px;
}
.w-card-footer a {
  color: rgba(0, 0, 0, 0.4);
}
