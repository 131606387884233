.w-pagination {
  padding: 0 !important;
  margin: 0 !important;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
  font-size: 12px;
}
.w-pagination-left {
  text-align: left;
}
.w-pagination-center {
  text-align: center;
}
.w-pagination-right {
  text-align: right;
}
.w-pagination > li {
  height: 28px;
  line-height: 28px;
  vertical-align: middle;
  text-align: center;
  position: relative;
  cursor: pointer;
  display: inline-flex !important;
  transition: background-color 0.2s ease-in, box-shadow 0.2s ease-in;
  margin: 0 !important;
  display: inline-flex;
  align-items: center;
}
.w-pagination > li > a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
  transition: none;
  margin: 0 10px;
  display: block;
}
.w-pagination > li > a.arrow {
  padding: 0 3px;
}
.w-pagination > li > a.arrow::after,
.w-pagination > li > a.arrow::before {
  content: '';
  display: block;
  height: 8px;
  width: 2px;
  border-radius: 2px;
  background-color: #565656;
  transition: all 0.3s;
}
.w-pagination > li > a.arrow::after {
  margin-top: -4px;
}
.w-pagination > li > a.arrow.prev::after,
.w-pagination > li > a.arrow.next::before {
  transform: rotate(-45deg);
}
.w-pagination > li > a.arrow.prev::before,
.w-pagination > li > a.arrow.next::after {
  transform: rotate(45deg);
}
.w-pagination > li.disabled {
  cursor: not-allowed;
}
.w-pagination > li.disabled > a.arrow::after,
.w-pagination > li.disabled > a.arrow::before {
  background: #d4d4d4;
}
.w-pagination.small > li {
  height: 21px;
  line-height: 21px;
  border-radius: 4px;
}
.w-pagination.small > li > a {
  margin: 0 3px;
  min-width: 15px;
}
.w-pagination.small > li > a.arrow::after,
.w-pagination.small > li > a.arrow::before {
  margin-left: 6px;
}
.w-pagination.small > li.active {
  background-color: #ececec;
}
.w-pagination.small > li:not(.active):hover a {
  color: #2ea3f4;
}
.w-pagination.default > li {
  background: #fff;
  border: 1px solid #d4d4d4;
  border-left: 0;
}
.w-pagination.default > li:not(.disabled):not(.active):hover {
  background-color: #f6f6f6;
}
.w-pagination.default > li:not(.disabled):active {
  box-shadow: inset 0 8px 42px -12px rgba(0, 0, 0, 0.2);
}
.w-pagination.default > li:first-child {
  border-left: 1px solid #d4d4d4;
  border-radius: 3px 0 0 3px;
}
.w-pagination.default > li:last-child {
  border-radius: 0 3px 3px 0;
}
.w-pagination.default > li.active {
  background-color: #ececec;
}
.w-pagination.default > li.active:active {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}
.w-pagination.default.divider li + li {
  margin-left: 8px !important;
  border: 1px solid #d4d4d4;
}
.w-pagination.default.divider li {
  border-radius: 4px;
}
.w-pagination-options {
  margin-left: 8px !important;
}
.w-pagination-options .w-select {
  border: none !important;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: none !important;
  height: 100%;
}
