.w-steps {
  font-size: 0;
  width: 100%;
  line-height: 1.5;
}
.w-steps .w-steps-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.w-steps .w-steps-item:last-child .w-steps-item-tail {
  display: none;
  text-align: center;
}
.w-steps .w-steps-item-tail {
  position: absolute;
  left: 0;
  width: 100%;
  top: 13px;
  padding: 0 10px;
  height: 1px;
}
.w-steps .w-steps-item-tail i {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #e9e9e9;
}
.w-steps .w-steps-item-tail i:after {
  position: absolute;
  content: ' ';
  top: 0;
  width: 0;
  height: 100%;
  transition: all 0.6s;
}
.w-steps .w-steps-item-head,
.w-steps .w-steps-item-main {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.w-steps .w-steps-item-head {
  padding-left: 10px;
  background: #fff;
}
.w-steps .w-steps-item-head:not(:first-child) {
  margin-left: -10px;
}
.w-steps .w-steps-item-title {
  padding-right: 10px;
  line-height: 26px;
  font-size: 14px;
  font-weight: 700;
  background: #fff;
  color: #999;
  display: inline-block;
}
.w-steps .w-steps-item-description {
  font-size: 12px;
  color: #999;
  max-width: 130px;
}
.w-steps .w-steps-item-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 26px;
  font-size: 14px;
  margin-right: 8px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}
.w-steps .w-steps-item-process .w-steps-item-inner,
.w-steps .w-steps-item-finish .w-steps-item-inner {
  border-color: #2d8cf0;
  background-color: #2d8cf0;
  color: #fff;
}
.w-steps .w-steps-item-process .w-steps-item-inner.is-icon .w-steps-icon,
.w-steps .w-steps-item-finish .w-steps-item-inner.is-icon .w-steps-icon {
  color: #108ee9;
}
.w-steps .w-steps-item-process [class^='w-icon-'],
.w-steps .w-steps-item-finish [class^='w-icon-'],
.w-steps .w-steps-item-process [class*=' w-icon-'],
.w-steps .w-steps-item-finish [class*=' w-icon-'] {
  vertical-align: middle;
  position: relative;
  top: -1px;
}
.w-steps .w-steps-item-finish .w-steps-item-tail i:after {
  background-color: #2d8cf0;
  width: 100%;
}
.w-steps .w-steps-item-process .w-steps-item-title,
.w-steps .w-steps-item-process .w-steps-item-description {
  color: rgba(0, 0, 0, 0.65);
}
.w-steps .w-steps-item-error .w-steps-item-inner {
  color: #f04134;
  border-color: #f04134;
  background-color: #fff;
}
.w-steps .w-steps-item-error .w-steps-item-title,
.w-steps .w-steps-item-error .w-steps-item-description,
.w-steps .w-steps-item-error .w-steps-icon,
.w-steps .w-steps-item-error i[class^='w-icon-'] {
  color: #f04134;
}
.w-steps .w-steps-next-error .w-steps-item-tail i:after {
  background-color: #f04134;
  width: 100%;
  transition: all 0.6s;
}
.w-steps-custom .w-steps-item-inner.is-icon {
  background: none;
  border: 0;
  width: 26px;
  height: 26px;
  font-size: 26px;
}
.w-steps-dot .w-steps-item-main {
  display: block;
  text-align: center;
}
.w-steps-dot .w-steps-item-inner {
  margin: 0 auto;
  width: 7px;
  height: 7px;
  line-height: 5px;
  border: 0;
}
.w-steps-dot .w-steps-item-title {
  padding-top: 5px;
}
.w-steps-dot .w-steps-item-description {
  max-width: initial;
}
.w-steps-dot .w-steps-item-error .w-steps-item-tail i:after {
  background-color: #2d8cf0;
}
.w-steps-dot .w-steps-item-error .w-steps-item-inner {
  background: #f04134;
}
.w-steps-dot .w-steps-item-error .w-steps-item-tail {
  padding-right: 50%;
  background: #e9e9e9;
}
.w-steps-dot .w-steps-item-error .w-steps-item-tail i:after {
  background-color: #f04134;
  width: 100%;
}
.w-steps-dot .w-steps-item-finish.w-steps-next-error .w-steps-item-tail i:after {
  background-color: #2d8cf0;
}
.w-steps-dot .w-steps-item-wait .w-steps-item-inner {
  border-color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.25);
}
.w-steps-dot .w-steps-item-wait .w-steps-item-inner .w-steps-dot {
  background: rgba(0, 0, 0, 0.25);
}
.w-steps-dot .w-steps-item-process .w-steps-item-tail {
  padding-right: 50%;
  background: #e9e9e9;
}
.w-steps-dot .w-steps-item-process .w-steps-item-tail i:after {
  background-color: #2d8cf0;
  width: 100%;
}
.w-steps-dot .w-steps-item-tail {
  padding: 0;
  width: 100%;
  position: initial;
  height: 2px;
}
.w-steps-dot .w-steps-item:first-child .w-steps-item-tail {
  padding-left: 50%;
}
.w-steps-dot .w-steps-item:last-child .w-steps-item-tail {
  display: block;
  padding-right: 50%;
}
.w-steps-dot .w-steps-item-head:not(:first-child) {
  margin-left: 0;
  padding-left: 0;
  text-align: center;
  display: block;
}
.w-steps-dot .w-steps-item-head {
  background: transparent;
  margin-top: -4px;
}
.w-steps-dot .w-steps-item-head .is-icon {
  margin-left: -10px !important;
  background-color: transparent;
}
.w-steps-dot.w-steps-vertical .w-steps-item:last-child .w-steps-item-tail {
  display: none;
}
.w-steps-dot.w-steps-vertical .w-steps-item .w-steps-item-tail {
  position: absolute;
  left: 3px;
  padding: 0 0 21px;
}
.w-steps-dot.w-steps-vertical .w-steps-item-inner {
  margin: 0;
}
.w-steps-dot.w-steps-vertical .w-steps-item-main {
  text-align: left;
  padding-left: 20px;
  padding-bottom: 24px;
  margin-top: -21px;
}
.w-steps-dot.w-steps-vertical .is-icon-main {
  margin-top: -35px;
}
.w-steps-dot.w-steps-vertical .w-steps-next-error .w-steps-item-tail i:after {
  background-color: #f04134;
}
.w-steps-dot.w-steps-vertical .w-steps-item-error .w-steps-item-tail {
  background-color: transparent;
}
.w-steps-dot.w-steps-vertical .w-steps-item-error .w-steps-item-tail i:after {
  background-color: transparent;
}
.w-steps-vertical > .w-steps-item {
  display: block;
}
.w-steps-vertical > .w-steps-item > .w-steps-item-tail {
  height: 100%;
  width: 1px;
  padding: 18px 0;
  left: 13px;
}
.w-steps-vertical .w-steps-item-main {
  padding-bottom: 12px;
  overflow: hidden;
}
.w-steps-vertical .w-steps-item .w-steps-item-main .w-steps-item-description {
  max-width: inherit;
}
