.w-back-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
  z-index: 1006;
  transition: all 1s;
}
.w-back-top.no-fixed {
  cursor: auto;
  position: static;
}
.w-back-top-hide {
  transition: all 1s;
  opacity: 0;
  height: 0px;
}
.w-back-top-show {
  opacity: 1;
}
