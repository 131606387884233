.w-datepicker-weekday,
.w-datepicker-week {
  display: flex;
  width: 100%;
}
.w-datepicker-weekday > div,
.w-datepicker-week > div {
  display: table-cell;
  flex: 1;
  min-width: 26px;
  min-height: 26px;
  vertical-align: middle;
  text-align: center;
}
.w-datepicker-weekday > div {
  font-weight: bold;
}
.w-datepicker-weekday > div.end,
.w-datepicker-week > div.end {
  color: #6f6f6f;
}
.w-datepicker-week > div {
  border-radius: 3px;
  cursor: pointer;
}
.w-datepicker-week > div.prev,
.w-datepicker-week > div.next {
  color: #d3d3d3;
}
.w-datepicker-week > div > div {
  transition: background-color 0.3s, color 0.3s;
  margin: 0 2px;
  border-radius: 3px;
  line-height: 22px;
}
.w-datepicker-week > div.today > div {
  background-color: #eaeaea;
}
.w-datepicker-week > div.selected > div,
.w-datepicker-week > div.selected:hover > div {
  color: #fff;
  background-color: #2ea3f4;
}
.w-datepicker-week > div:hover > div {
  background-color: #eaeaea;
  color: #393e48;
}
.w-datepicker-week > div:active > div {
  background-color: #d2d2d2;
}
.w-datepicker-week > div.disabled > div,
.w-datepicker-week > div.disabled:hover > div {
  color: #d3d3d3;
  cursor: not-allowed;
  background-color: #f5f5f5;
}
.w-datepicker-week > div.disabled.today > div,
.w-datepicker-week > div.disabled:hover.today > div {
  background-color: rgba(189, 189, 189, 0.47);
  color: #fff;
}
.w-datepicker-week > div.disabled.selected > div,
.w-datepicker-week > div.disabled:hover.selected > div {
  background-color: rgba(46, 163, 244, 0.47);
  color: #fff;
}
.w-datepicker-body {
  padding-top: 5px;
}
