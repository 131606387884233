.w-checkbox {
  vertical-align: middle;
  font-size: 0;
  cursor: pointer;
  white-space: nowrap;
}
.w-checkbox input[type='checkbox'] {
  outline: none;
  width: 14px;
  height: 14px;
  font-size: 14px;
  line-height: 14px;
  border-radius: 2px;
  background-clip: border-box;
  -webkit-appearance: none;
          appearance: none;
  margin: 0 !important;
  background-color: #d7d7d7;
  transition: background-color 0.3s, box-shadow 0.3s;
}
.w-checkbox input[type='checkbox']:after {
  content: '';
  box-sizing: inherit;
}
.w-checkbox input[type='checkbox']:not(:checked):not(:disabled):not(.disabled):focus,
.w-checkbox input[type='checkbox']:not(:checked):not(:disabled):not(.disabled):hover {
  box-shadow: inset 0 1px 2px rgba(16, 22, 26, 0.35);
}
.w-checkbox.disabled,
.w-checkbox.disabled input[type='checkbox'] {
  cursor: not-allowed;
}
.w-checkbox.disabled input[type='checkbox'] {
  opacity: 0.5;
}
.w-checkbox.disabled .w-checkbox-text {
  color: #6e6e6e;
}
.w-checkbox.indeterminate input[type='checkbox'],
.w-checkbox.indeterminate input[type='checkbox']:checked {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #008ef0;
}
.w-checkbox.indeterminate input[type='checkbox']:after,
.w-checkbox.indeterminate input[type='checkbox']:checked:after {
  display: inline-block;
  background-color: #008df8;
  box-sizing: inherit;
  transform: rotate(0);
  position: relative;
  top: -1px;
  right: -3px;
  border-width: 0;
  border-radius: 2px;
  height: 8px;
  width: 8px;
}
.w-checkbox input[type='checkbox'],
.w-checkbox-text {
  vertical-align: middle;
}
.w-checkbox input[type='checkbox']:checked {
  background-color: #008ef0;
}
.w-checkbox input[type='checkbox']:checked:after {
  transition: background-color 0.2s ease-in;
  display: inline-block;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(33deg);
  position: relative;
  top: -1px;
  right: -4px;
  height: 10px;
  width: 6px;
}
.w-checkbox-text {
  display: inline-block;
  padding-left: 4px;
  margin-right: 5px;
  font-size: 14px;
}
