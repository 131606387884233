.w-btn-group > .w-btn {
  border-radius: 0;
  box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.17), inset -1px 0 0 0 rgba(0, 0, 0, 0.17), inset -1px 0px 0 0 rgba(0, 0, 0, 0.17);
}
.w-btn-group > .w-btn:last-child {
  border-radius: 0 3px 3px 0;
}
.w-btn-group > .w-btn:first-child {
  border-radius: 3px 0 0 3px;
}
.w-btn-group > .w-btn:first-child:last-child {
  border-radius: 3px !important;
}
.w-btn-group > .w-btn:focus {
  z-index: 2;
}
.w-btn-group .w-btn + .w-btn {
  margin-left: -1px !important;
}
.w-btn-group .w-btn:not(.w-btn-light):first-child {
  box-shadow: inset -1px 0px 0 0 rgba(0, 0, 0, 0.17) !important;
}
.w-btn-group .w-btn:not(.w-btn-light):last-child {
  box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.17), inset 0px 0 0 0 rgba(0, 0, 0, 0.17);
}
.w-btn-group .w-btn-light {
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.17), inset 1px -1px 0 0 rgba(0, 0, 0, 0.17), inset -1px 0px 0 0 rgba(0, 0, 0, 0.17) !important;
}
.w-btn-group-vertical .w-btn:not(.w-btn-light) {
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.17), inset 0 1px 0 0 rgba(0, 0, 0, 0.17) !important;
}
.w-btn-group-vertical .w-btn:not(.w-btn-light):first-child {
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.17), inset 0 0 0 0 rgba(0, 0, 0, 0.17) !important;
}
.w-btn-group-vertical .w-btn {
  width: 100%;
}
.w-btn-group-vertical .w-btn + .w-btn {
  margin-left: 0 !important;
  margin-top: -1px !important;
}
.w-btn-group-vertical .w-btn:last-child {
  border-radius: 0 0 3px 3px;
}
.w-btn-group-vertical .w-btn:first-child {
  border-radius: 3px 3px 0 0;
}
