.title {
  color: #fff;
  padding: 20px 15px 10px;
  margin: 0;
  font-size: 20px;
  font-weight: normal;
  span {
    vertical-align: middle;
    color: #b1b1b1;
  }
  svg {
    vertical-align: middle;
    fill: #b1b1b1;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    path {
      fill: #b1b1b1;
    }
  }
}

.menu {
  padding-bottom: 30px;
  padding-left: 10px;
  a {
    padding: 5px 8px;
    display: block;
    color: #fff;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    &:last-child,
    > svg {
      margin-bottom: 0;
      fill: #888;
    }
    &:hover,
    &:global(.active) {
      color: #fff;
      background: rgba(255, 255, 255, 0.12);
    }
  }
  .divider {
    padding: 10px;
    color: #aaa;
    font-weight: normal;
  }
  &::-webkit-scrollbar {
    border-radius: 5px;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgb(32, 34, 37);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.34);
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: transparent;
  }
}

// .topmenu {
//   .title {
//     svg path {
//       fill: #202225;
//     }
//     span {
//       color: #202225;
//     }
//   }
//   .menu a {
//     color: #202225;
//     &:global(.active),
//     &:hover {
//       background: rgba(0, 0, 0, 0.07);
//     }
//   }
//   .menu {
//     &::-webkit-scrollbar-thumb {
//       background-color: #d4d4d4;
//     }
//     &::-webkit-scrollbar-thumb:hover {
//       background-color: #333;
//     }
//   }
// }
