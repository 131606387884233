.w-datepicker-month,
.w-datepicker-year {
  text-align: center;
  max-width: 100%;
  padding: 10px 0 9px 0;
}
.w-datepicker-month > div,
.w-datepicker-year > div {
  display: inline-block;
  width: 50%;
  line-height: 20px;
  margin: 3px 0;
}
.w-datepicker-month > div > span,
.w-datepicker-year > div > span {
  display: inline-block;
  padding: 1px 6px;
  border-radius: 3px;
  cursor: pointer;
}
.w-datepicker-month > div.selected > span,
.w-datepicker-year > div.selected > span {
  color: #fff;
  background-color: #2ea3f4;
}
.w-datepicker-month > div > span:hover,
.w-datepicker-year > div > span:hover {
  background-color: #dcf0fd;
  color: #2ea3f4;
}
.w-datepicker-month > div.paging,
.w-datepicker-year > div.paging {
  color: rgba(0, 0, 0, 0.25);
}
.w-datepicker-year > div {
  width: 33.333%;
}
