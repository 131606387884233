.w-input {
  position: relative;
  font-size: 14px;
  line-height: 14px;
  width: 100%;
}
.w-input-inner {
  outline: none;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-sizing: border-box;
  background: #fff;
  height: 30px;
  margin: 0 !important;
  padding: 0 10px;
  vertical-align: middle;
  line-height: 30px;
  color: #393e48;
  font-weight: 400;
  font-size: inherit;
  transition: box-shadow 0.3s cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-appearance: none;
          appearance: none;
}
.w-input-inner:not(:first-child) {
  padding-left: 26px;
}
.w-input-inner:focus {
  box-shadow: 0 0 0 1px #393e48, 0 0 0 3px rgba(57, 62, 72, 0.17), inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
.w-input-inner:hover {
  box-shadow: 0 0 0 1px #6e6e6e, 0 0 0 3px rgba(57, 62, 72, 0), inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
.w-input-inner:focus.w-input-inner:hover {
  box-shadow: 0 0 0 1px #6e6e6e, 0 0 0 3px rgba(57, 62, 72, 0.17), inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
.w-input-inner:disabled {
  box-shadow: none;
  background: #dddddd;
  opacity: 0.75;
  color: #a5a5a5;
  cursor: not-allowed;
  resize: none;
}
.w-input .w-input-inner {
  width: 100%;
}
.w-input > .w-icon {
  position: absolute;
  margin: 0 7px 0 7px;
  transform: translateY(-50%);
  top: 50%;
}
.w-input-addon-after {
  position: absolute;
  top: 3px;
  right: 3px;
  display: flex;
  bottom: 3px;
}
.w-input-addon-after > * {
  display: flex !important;
  align-items: center;
}
.w-input-large {
  font-size: 16px;
}
.w-input-large .w-input-inner {
  line-height: 36px;
  height: 36px;
}
.w-input-small {
  min-width: 20px;
}
.w-input-small .w-input-inner {
  line-height: 24px;
  height: 24px;
  padding: 0 6px;
}
.w-input-small .w-input-inner:not(:first-child) {
  padding-left: 26px;
}
.w-input-small .w-input-addon-after > * {
  line-height: 16px;
  min-height: 16px;
}
