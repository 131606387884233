.w-message {
  padding: 10px 15px;
  position: relative;
  font-size: 14px;
}
.w-message + .w-message {
  margin-top: 10px;
}
.w-message-title,
.w-message-description {
  display: block;
}
.w-message-rounded {
  border-radius: 5px;
}
.w-message-description {
  color: rgba(0, 0, 0, 0.65);
}
.w-message-title {
  color: rgba(0, 0, 0, 0.85);
}
.w-message-title-description > .w-icon {
  font-size: 24px;
}
.w-message-title-description .w-message-title {
  font-size: 16px;
}
.w-message-title-description .w-message-title,
.w-message-title-description .w-message-description {
  padding-left: 16px;
}
.w-message-icon {
  padding-left: 34px;
}
.w-message-success {
  background: #afecbd;
  color: #28a745;
}
.w-message > button {
  position: absolute;
  right: 10px;
  top: 10px;
}
.w-message > button.w-btn {
  padding: 2px;
  min-width: 16px;
  min-height: 16px;
  color: rgba(0, 0, 0, 0.38);
}
.w-message > button.w-btn:hover {
  background-color: rgba(255, 255, 255, 0.21) !important;
}
.w-message > button.w-btn:active {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.w-message-warning {
  background: #fff4d3;
  color: #ffc107;
}
.w-message-info {
  background: #bde4ff;
  color: #008ef0;
}
.w-message-error {
  background: #fae3e5;
  color: #dc3545;
}
.w-message > .w-icon {
  top: 14px;
  left: 14px;
  position: absolute;
}
.w-message-enter {
  transform: scaleY(0.5);
  opacity: 0;
}
.w-message-enter-active {
  opacity: 1;
  transform: scaleY(1);
  transition: transform 300ms ease, opacity 300ms ease;
}
.w-message-exit {
  opacity: 1;
  transform: scaleY(1);
  transition: transform 300ms ease, opacity 300ms ease;
}
.w-message-exit-active {
  transform: scaleY(0.5);
  opacity: 0;
}
