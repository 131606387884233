.w-modal {
  z-index: 1001;
}
.w-modal-inner {
  padding-bottom: 20px;
  background: #f9f9f9;
  min-width: 320px;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2);
}
.w-modal-header {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding-left: 13px;
  padding-right: 5px;
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
  font-size: 16px;
}
.w-modal-header > .w-icon {
  margin-right: 10px;
  color: #393e48;
}
.w-modal-header h4 {
  margin: 0;
  padding: 0;
  flex: 1 1 auto;
  font-weight: bold;
  min-height: 22px;
}
.w-modal-body,
.w-modal-footer {
  margin: 0 20px;
}
.w-modal-body {
  padding-top: 20px;
  font-size: 14px;
}
.w-modal-footer {
  display: flex;
  margin-top: 15px;
  flex-direction: row-reverse;
}
.w-modal-footer .w-btn + .w-btn {
  margin-right: 10px;
}
