.w-row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
}
.w-row.w-row-justify-flex-start {
  justify-content: flex-start;
}
.w-row.w-row-justify-flex-end {
  justify-content: flex-end;
}
.w-row.w-row-justify-center {
  justify-content: center;
}
.w-row.w-row-justify-space-between {
  justify-content: space-between;
}
.w-row.w-row-justify-space-around {
  justify-content: space-around;
}
.w-row.w-row-justify-space-evenly {
  justify-content: space-evenly;
}
.w-row-align-top {
  align-items: flex-start;
}
.w-row-align-middle {
  align-items: center;
}
.w-row-align-bottom {
  align-items: flex-end;
}
.w-row-align-baseline {
  align-items: baseline;
}
