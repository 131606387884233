.w-descriptions table {
  display: table !important;
  margin-bottom: 0 !important;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100% !important;
}
.w-descriptions {
  background: #fff;
}
.w-descriptions-title {
  font-weight: bold;
  text-align: left;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5;
}
.w-descriptions-tbody {
  word-wrap: break-word;
}
.w-descriptions .w-descriptions-row:nth-child(2n) {
  background-color: transparent;
}
.w-descriptions-item-label {
  font-size: 14px;
  font-weight: normal;
}
.w-descriptions-item-label::after {
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
  content: ' ';
}
.w-descriptions-item-colon::after {
  content: ':';
}
.w-descriptions-item-colon {
  color: rgba(0, 0, 0, 0.95);
}
.w-descriptions-item-content {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
.w-descriptions-small.w-descriptions-bordered tbody tr th,
.w-descriptions-small.w-descriptions-bordered tbody .w-descriptions-row td {
  padding: 4px 8px;
}
.w-descriptions-large.w-descriptions-bordered tbody tr th,
.w-descriptions-large.w-descriptions-bordered tbody .w-descriptions-row td {
  padding: 14px 16px;
}
.w-descriptions-bordered {
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid #dfe2e5;
}
.w-descriptions-bordered tbody tr th {
  padding: 8px 12px;
  background-color: #fafafa;
  font-weight: normal;
}
.w-descriptions-bordered tbody tr th,
.w-descriptions-bordered tbody tr td {
  border-top: 1px solid #dfe2e5;
  border-right: 1px solid #dfe2e5;
  border-left: 0;
  border-bottom: 0;
}
.w-descriptions-bordered tbody:first-child tr:first-child th,
.w-descriptions-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}
.w-descriptions:not(.w-descriptions-bordered) .w-descriptions-row td {
  padding-bottom: 8px;
}
.w-descriptions-bordered .w-descriptions-row td {
  padding: 8px 12px;
}
.w-descriptions-bordered .w-descriptions-title {
  margin-bottom: 0;
  padding: 7px 12px;
}
.w-descriptions-bordered .w-descriptions-item-label:last-child,
.w-descriptions-bordered .w-descriptions-item-content:last-child {
  border-right: none;
}
