.w-fileinput input::-webkit-file-upload-button {
  background: transparent;
  border: 0;
  color: #0000;
  position: absolute;
  left: -300px;
}
.w-fileinput input:hover:after {
  background-clip: padding-box;
  background-color: #ebf1f5;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
}
.w-fileinput input:after {
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.08);
  background-color: #f5f8fa;
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0));
  color: #182026;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  border-radius: 3px;
  content: 'Browse';
  content: attr(data-label);
  padding: 0 10px;
  margin: 3px;
  text-align: center;
  right: 0;
  display: flex;
  align-items: center;
}
.w-fileinput input::before,
.w-fileinput input::after {
  color: #757575;
  position: absolute;
  top: 0;
  bottom: 0;
}
.w-fileinput-card {
  margin-right: -8px;
}
.w-fileinput-card-box {
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  overflow: hidden;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
  display: inline-flex;
  position: relative;
}
.w-fileinput-card-box-info {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.w-fileinput-card-box-info img {
  width: 100%;
  height: 100%;
}
.w-fileinput-card-actions {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
}
.w-fileinput-card-actions-remove {
  width: 24px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.w-fileinput-card-btn {
  align-items: center;
  justify-content: center;
}
.w-fileinput-card-box:hover .w-fileinput-card-actions {
  opacity: 1;
}
.w-fileinput-card-size-large .w-fileinput-card-box {
  width: 110px;
  height: 110px;
}
.w-fileinput-card-size-middle .w-fileinput-card-box {
  width: 80px;
  height: 80px;
}
.w-fileinput-card-size-small .w-fileinput-card-box {
  width: 50px;
  height: 50px;
}
.w-fileinput-card-shape-round .w-fileinput-card-box {
  border-radius: 2px;
}
.w-fileinput-card-shape-circle .w-fileinput-card-box {
  border-radius: 50%;
}
.w-fileinput-list {
  padding-bottom: 8px;
  width: 100%;
}
.w-fileinput-list-actions {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
}
.w-fileinput-list-actions-search {
  width: 24px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.w-fileinput-list-info:hover .w-fileinput-list-actions {
  opacity: 1;
}
.w-fileinput-list-picture {
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  padding: 5px;
  margin-top: 8px;
}
.w-fileinput-list-picture-info {
  margin-right: 10px;
  position: relative;
  overflow: hidden;
}
.w-fileinput-list-picture-info img {
  width: 48px;
  height: 48px;
  display: block;
}
.w-fileinput-list-picture-text {
  flex: 1;
}
.w-fileinput-list-picture-icon {
  padding: 5px;
  cursor: pointer;
}
.w-fileinput-list-size-large .w-fileinput-list-picture-info img {
  width: 70px;
  height: 70px;
}
.w-fileinput-list-size-middle .w-fileinput-list-picture-info img {
  width: 50px;
  height: 50px;
}
.w-fileinput-list-size-small .w-fileinput-list-picture-info img {
  width: 30px;
  height: 30px;
}
.w-fileinput-list-shape-round .w-fileinput-list-picture-info {
  border-radius: 2px;
}
.w-fileinput-list-shape-circle .w-fileinput-list-box-info {
  border-radius: 50px;
}
.w-fileinput-list-text {
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  padding: 5px;
  transition: all 0.5s;
}
.w-fileinput-list-text:hover {
  background: #f5f5f5;
}
.w-fileinput-list-text:first-child {
  margin-top: 8px;
}
.w-fileinput-list-text-text {
  flex: 1;
}
.w-fileinput-list-text-icon {
  padding: 0 5px;
  cursor: pointer;
}
