.w-drawer-wrapper {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 46px 6px rgba(16, 22, 26, 0.2);
  background-color: #fff;
  position: fixed;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
}
.w-drawer-header {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding-left: 13px;
  padding-right: 5px;
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
  font-size: 16px;
}
.w-drawer-header > .w-icon {
  margin-right: 10px;
  color: #393e48;
}
.w-drawer-header h4 {
  margin: 0;
  padding: 0;
  flex: 1 1 auto;
}
.w-drawer-body {
  flex: 1 1 auto;
  overflow: auto;
  line-height: 18px;
}
.w-drawer-footer {
  box-shadow: 0 -1px 0 rgba(16, 22, 26, 0.15);
  min-height: 40px;
  padding-left: 13px;
  padding-right: 5px;
  display: flex;
  align-items: center;
}
.w-drawer-body-inner {
  padding: 15px;
}
.w-drawer.top .w-drawer-wrapper,
.w-drawer.bottom .w-drawer-wrapper {
  left: 0;
  right: 0;
}
.w-drawer.top .w-drawer-wrapper {
  top: 0;
}
.w-drawer.bottom .w-drawer-wrapper {
  bottom: 0;
}
.w-drawer.right .w-drawer-wrapper,
.w-drawer.left .w-drawer-wrapper {
  bottom: 0;
  top: 0;
}
.w-drawer.right .w-drawer-wrapper {
  right: 0;
}
.w-drawer.left .w-drawer-wrapper {
  left: 0;
}
.w-drawer.w-overlay-enter .w-overlay-content {
  opacity: 1;
}
.w-drawer.w-overlay-enter-active .w-overlay-content {
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
.w-drawer.w-overlay-exit .w-overlay-content {
  opacity: 1;
}
.w-drawer.w-overlay-exit-active .w-overlay-content {
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
.w-drawer.right.w-overlay-enter .w-overlay-content {
  transform: translateX(100%);
}
.w-drawer.right.w-overlay-enter-active .w-overlay-content {
  transform: translateX(0);
}
.w-drawer.right.w-overlay-exit .w-overlay-content {
  transform: translateX(0);
}
.w-drawer.right.w-overlay-exit-active .w-overlay-content {
  transform: translateX(100%);
}
.w-drawer.left.w-overlay-enter .w-overlay-content {
  transform: translateX(-100%);
}
.w-drawer.left.w-overlay-enter-active .w-overlay-content {
  transform: translateX(0);
}
.w-drawer.left.w-overlay-exit .w-overlay-content {
  transform: translateX(0);
}
.w-drawer.left.w-overlay-exit-active .w-overlay-content {
  transform: translateX(-100%);
}
.w-drawer.top.w-overlay-enter .w-overlay-content {
  transform: translateY(-100%);
}
.w-drawer.top.w-overlay-enter-active .w-overlay-content {
  transform: translateY(0);
}
.w-drawer.top.w-overlay-exit .w-overlay-content {
  transform: translateY(0);
}
.w-drawer.top.w-overlay-exit-active .w-overlay-content {
  transform: translateY(-100%);
}
.w-drawer.bottom.w-overlay-enter .w-overlay-content {
  transform: translateY(100%);
}
.w-drawer.bottom.w-overlay-enter-active .w-overlay-content {
  transform: translateY(0);
}
.w-drawer.bottom.w-overlay-exit .w-overlay-content {
  transform: translateY(0);
}
.w-drawer.bottom.w-overlay-exit-active .w-overlay-content {
  transform: translateY(100%);
}
