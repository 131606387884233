.w-notify {
  position: relative !important;
  z-index: 9999;
}
.w-notify-warpper .w-notify .w-modal-inner .w-modal-header > .w-icon {
  font-size: 28px !important;
}
.w-notify-warpper .w-notify .w-modal-inner .w-modal-header > .w-btn {
  min-width: 18px;
  min-height: 18px;
  padding: 5px 5px;
}
.w-notify-warpper .w-notify .w-modal-inner.w-modal-shown-icon:not(.w-modal-shown-title) .w-modal-header > button {
  top: 12px;
  right: 10px;
  position: absolute;
}
.w-notify-warpper .w-notify .w-modal-inner.w-modal-shown-icon:not(.w-modal-shown-title) .w-modal-body {
  padding-right: 36px;
}
.w-notify-warpper .w-notify .w-modal-inner.w-modal-shown-title .w-modal-header {
  padding-top: 20px;
}
.w-notify-warpper .w-notify .w-modal-inner.w-modal-shown-title .w-modal-header > button {
  top: 5px;
  right: 5px;
  position: absolute;
}
.w-notify-warpper .w-notify .w-modal-inner.w-modal-shown-title .w-modal-header > h4 {
  padding-left: 40px;
  padding-right: 20px;
}
.w-notify-warpper .w-notify .w-modal-inner.w-modal-shown-title .w-modal-body {
  padding-left: 60px;
  opacity: 0.75;
}
.w-notify-warpper .w-notify .w-modal-inner.w-modal-shown-title:not(.w-modal-shown-icon) .w-modal-header > h4 {
  padding-left: 5px;
}
.w-notify-warpper .w-notify .w-modal-inner.w-modal-shown-title:not(.w-modal-shown-icon) .w-modal-body {
  padding-left: 25px;
}
.w-notify-warpper .w-notify .w-modal-inner:not(.w-modal-shown-title):not(.w-modal-shown-icon) .w-modal-body {
  padding: 16px;
}
.w-notify-warpper .w-notify .w-modal-inner:not(.w-modal-shown-title).w-modal-inner {
  padding-bottom: 0;
}
.w-notify-warpper .w-notify .w-modal-inner:not(.w-modal-shown-title).w-modal-inner .w-modal-header {
  padding-top: 16px;
}
.w-notify-warpper .w-notify .w-modal-inner:not(.w-modal-shown-title).w-modal-inner .w-modal-header > .w-icon {
  font-size: 20px !important;
}
.w-notify-warpper .w-notify .w-modal-inner:not(.w-modal-shown-title) .w-modal-body {
  padding: 16px 12px;
}
.w-notify-warpper .w-notify .w-overlay-content {
  margin: 5px;
}
.w-notify-warpper {
  position: fixed;
  padding: 5px;
  z-index: 999;
}
.w-notify-warpper.topLeft {
  top: 0;
  left: 0;
}
.w-notify-warpper.topRight {
  top: 0;
  right: 0;
}
.w-notify-warpper.bottomLeft {
  bottom: 0;
  left: 0;
}
.w-notify-warpper.bottomRight {
  bottom: 0;
  right: 0;
}
