.w-tree {
  font-size: 14px;
}
.w-tree ul {
  padding: 0 !important;
  transition: 0.3s all;
  overflow: hidden;
  margin: 0;
}
.w-tree ul ul {
  padding-left: 18px !important;
  margin-bottom: 0;
}
.w-tree li {
  list-style: none !important;
}
.w-tree li + li {
  margin-top: 2px !important;
}
.w-tree li:first-child {
  padding-top: 3px;
}
.w-tree-close {
  height: 0;
}
.w-tree-label .no-child:not(.w-tree-switcher-noop) svg {
  display: none;
}
.w-tree-label {
  line-height: initial;
}
.w-tree-label > * {
  vertical-align: middle;
}
.w-tree-switcher {
  cursor: pointer;
  position: relative;
  z-index: 1;
  width: 14px;
  height: 14px;
  line-height: 14px;
  display: inline-block;
  text-align: center;
}
.w-tree-switcher:hover {
  color: #2ea3f4;
}
.w-tree-switcher .w-icon {
  transition: 0.3s all;
  transform: scale(0.79) rotate(0deg);
}
.w-tree-switcher .w-icon.open:not(.no-animation) {
  transform: scale(0.79) rotate(90deg) !important;
}
.w-tree-title {
  display: inline-block;
  padding: 2px 5px;
  cursor: pointer;
}
.w-tree-title > * {
  vertical-align: middle;
}
.w-tree-disabled-mouse {
  cursor: not-allowed;
}
.w-tree-disabled-ele {
  color: #00000040;
}
.w-tree-title.selected {
  background-color: #d5e8fc;
}
.w-tree-line li {
  position: relative;
}
.w-tree-line li li:before,
.w-tree-line li li::after {
  content: ' ';
  border-left: 1px solid #d9d9d9;
  left: -12px;
  position: absolute;
}
.w-tree-line li li::after {
  height: 100%;
  top: 5px;
}
.w-tree-line li li:last-child::after {
  height: 16px;
  top: -18px;
}
.w-tree-line li li:before {
  content: ' ';
  width: 10px;
  height: 16px;
  border-bottom: 1px solid #d9d9d9;
  top: -2px;
}
.w-tree-line li li:last-child::before {
  border-radius: 0 0 0 3px;
}
