.w-badge {
  position: relative;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
}
.w-badge sup.w-badge-count {
  position: absolute;
  transform: translateX(-50%);
  top: -10px;
  height: 16px;
  border-radius: 10px;
  min-width: 16px;
  background: #f04134;
  color: #fff;
  line-height: 16px;
  text-align: center;
  padding: 0 5px;
  font-size: 12px;
  white-space: nowrap;
  transform-origin: -10% center;
  font-family: tahoma;
  box-shadow: 0 0 0 1px #fff;
}
.w-badge.nowrap sup.w-badge-count {
  top: auto;
  display: block;
  position: relative;
  transform: none !important;
  overflow: hidden;
}
.w-badge sup.dot {
  position: absolute;
  transform: translateX(-50%);
  transform-origin: 0 center;
  overflow: hidden;
  color: transparent;
  top: -4px;
  height: 6px;
  width: 6px;
  padding: 0;
  border-radius: 100%;
  background: #f04134;
  z-index: 10;
  box-shadow: 0 0 0 1px #fff;
}
.w-badge-dot {
  line-height: inherit;
  vertical-align: baseline;
  font-size: 14px;
  margin: 0 4px;
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
.w-badge-processing .w-badge-dot {
  position: relative;
  background-color: #007bff;
}
.w-badge-processing .w-badge-dot:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: inherit;
  content: '';
  animation: wStatusProcessing 1.2s infinite ease-in-out;
}
@keyframes wStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.8);
    opacity: 0;
  }
}
