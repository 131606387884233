.w-progress-line {
  width: 100%;
  font-size: 12px;
}
.w-progress-bar,
.w-progress-text {
  box-sizing: border-box;
}
.w-progress-bar {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.w-progress-status-active .w-progress-bg {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 12px 12px;
  animation: progress-bar-stripes 1s linear infinite;
}
.w-progress-status-active .w-progress-bg:before {
  content: ' ';
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  border-radius: 10px;
  animation: w-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
}
.w-progress-status-exception .w-progress-bg {
  background-color: #dc3545;
}
.w-progress-status-exception .w-progress-text {
  color: #dc3545;
}
.w-progress-status-success .w-progress-bg {
  background-color: #28a745;
}
.w-progress-status-success .w-progress-text {
  color: #28a745;
}
.w-progress-show-text .w-progress-bar {
  margin-right: -50px;
  padding-right: 50px;
}
.w-progress-inner {
  height: 100%;
  width: 100%;
  border-radius: 100px;
  background-color: #e5e9f2;
  overflow: hidden;
}
.w-progress-bg {
  height: 100%;
  border-radius: 100px;
  background-color: #108ee9;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  position: relative;
}
@keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 24px 0;
  }
}
@keyframes w-progress-active {
  0% {
    opacity: 0.1;
    width: 0;
  }
  20% {
    opacity: 0.5;
    width: 0;
  }
  100% {
    opacity: 0;
    width: 100%;
  }
}
