.w-search-tree-input-contents {
  flex: 1;
  top: 1px;
}
.w-search-tree-input-contents input {
  box-shadow: none;
  padding: 0px;
  height: 20px;
  min-width: 50px;
}
.w-search-tree-input-contents .w-input-inner:hover {
  box-shadow: none !important;
}
.w-search-tree-input-contents .w-input-inner:focus {
  box-shadow: none !important;
}
.w-search-tree-inner {
  display: flex;
  justify-content: space-between;
  outline: none;
  border: none;
  align-items: center;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-sizing: border-box;
  background: #fff;
  min-height: 30px;
  margin: 0 !important;
  padding: 3px 10px 3px 10px;
  vertical-align: middle;
  line-height: 30px;
  color: #393e48;
  font-weight: 400;
  font-size: inherit;
  transition: box-shadow 0.3s cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-appearance: none;
          appearance: none;
}
.w-search-tree-inner:focus {
  box-shadow: 0 0 0 1px #393e48, 0 0 0 3px rgba(57, 62, 72, 0.17), inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
.w-search-tree-inner:hover {
  box-shadow: 0 0 0 1px #6e6e6e, 0 0 0 3px rgba(57, 62, 72, 0), inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
.w-search-tree-inner:focus.w-search-tree-inner:hover {
  box-shadow: 0 0 0 1px #6e6e6e, 0 0 0 3px rgba(57, 62, 72, 0.17), inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
.w-search-tree-inner:disabled {
  box-shadow: none;
  background: #dddddd;
  opacity: 0.75;
  color: #a5a5a5;
  cursor: not-allowed;
  resize: none;
}
.w-search-tree-inner-close-tag-contents {
  width: 14px;
  display: flex;
  align-content: center;
}
.w-search-tree-inner-inner-small {
  height: 20px;
}
.w-search-tree-inner-inner-large {
  height: 30px;
}
.w-search-tree-inner .w-input-small .w-input-inner {
  height: 16px;
  font-size: 10px;
  padding: 0px;
}
.w-search-tree-inner .w-input-large .w-input-inner {
  height: 28px;
}
