.w-btn {
  -webkit-user-select: none;
          user-select: none;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px 7px;
  position: relative;
  vertical-align: middle;
  text-align: left;
  line-height: 14px;
  font-size: 14px;
  min-width: 30px;
  min-height: 30px;
  text-align: center;
  color: #fff;
  transition: background-color 0.5s, opacity 1s;
}
.w-btn > *:not(:last-child) {
  margin-right: 5px;
}
.w-btn + .w-btn:not(.block) {
  margin-left: 5px;
}
.w-btn.block {
  display: block;
  width: 100%;
}
.w-btn.disabled,
.w-btn[disabled] {
  cursor: not-allowed;
}
.w-btn-primary {
  color: #fff;
  background-color: #008ef0;
  z-index: 1;
}
.w-btn-primary:hover {
  background-color: #0070bd;
}
.w-btn-primary:focus,
.w-btn-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 142, 240, 0.26);
}
.w-btn-primary:hover {
  color: #fff;
  background-color: #0070bd;
  z-index: 2;
}
.w-btn-primary:active,
.w-btn-primary.active {
  color: #fff;
  background-color: #00528a;
  background-image: none;
}
.w-btn-primary.disabled,
.w-btn-primary[disabled] {
  background-color: #57baff;
  z-index: 0;
}
.w-btn-primary.w-btn-basic {
  background-color: transparent !important;
  box-shadow: inset 0 0 0 #000;
  color: #008ef0;
}
.w-btn-primary.w-btn-basic:hover {
  background-color: #c7e8ff !important;
}
.w-btn-primary.w-btn-basic:active,
.w-btn-primary.w-btn-basic.active {
  color: #008ef0;
  background-color: #94d3ff !important;
  background-image: none;
}
.w-btn-primary.w-btn-basic.disabled,
.w-btn-primary.w-btn-basic[disabled] {
  background-color: transparent !important;
  color: #24a6ff;
}
.w-btn-success {
  color: #fff;
  background-color: #28a745;
  z-index: 1;
}
.w-btn-success:hover {
  background-color: #1e7e34;
}
.w-btn-success:focus,
.w-btn-success.focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(40, 167, 69, 0.26);
}
.w-btn-success:hover {
  color: #fff;
  background-color: #1e7e34;
  z-index: 2;
}
.w-btn-success:active,
.w-btn-success.active {
  color: #fff;
  background-color: #145523;
  background-image: none;
}
.w-btn-success.disabled,
.w-btn-success[disabled] {
  background-color: #5dd879;
  z-index: 0;
}
.w-btn-success.w-btn-basic {
  background-color: transparent !important;
  box-shadow: inset 0 0 0 #000;
  color: #28a745;
}
.w-btn-success.w-btn-basic:hover {
  background-color: #b7eec4 !important;
}
.w-btn-success.w-btn-basic:active,
.w-btn-success.w-btn-basic.active {
  color: #28a745;
  background-color: #8ee4a2 !important;
  background-image: none;
}
.w-btn-success.w-btn-basic.disabled,
.w-btn-success.w-btn-basic[disabled] {
  background-color: transparent !important;
  color: #34ce57;
}
.w-btn-warning {
  color: #fff;
  background-color: #ffc107;
  z-index: 1;
}
.w-btn-warning:hover {
  background-color: #d39e00;
}
.w-btn-warning:focus,
.w-btn-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 193, 7, 0.26);
}
.w-btn-warning:hover {
  color: #fff;
  background-color: #d39e00;
  z-index: 2;
}
.w-btn-warning:active,
.w-btn-warning.active {
  color: #fff;
  background-color: #a07800;
  background-image: none;
}
.w-btn-warning.disabled,
.w-btn-warning[disabled] {
  background-color: #ffdb6d;
  z-index: 0;
}
.w-btn-warning.w-btn-basic {
  background-color: transparent !important;
  box-shadow: inset 0 0 0 #000;
  color: #ffc107;
}
.w-btn-warning.w-btn-basic:hover {
  background-color: #fff7dd !important;
}
.w-btn-warning.w-btn-basic:active,
.w-btn-warning.w-btn-basic.active {
  color: #ffc107;
  background-color: #ffeaaa !important;
  background-image: none;
}
.w-btn-warning.w-btn-basic.disabled,
.w-btn-warning.w-btn-basic[disabled] {
  background-color: transparent !important;
  color: #ffce3a;
}
.w-btn-danger {
  color: #fff;
  background-color: #dc3545;
  z-index: 1;
}
.w-btn-danger:hover {
  background-color: #bd2130;
}
.w-btn-danger:focus,
.w-btn-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.26);
}
.w-btn-danger:hover {
  color: #fff;
  background-color: #bd2130;
  z-index: 2;
}
.w-btn-danger:active,
.w-btn-danger.active {
  color: #fff;
  background-color: #921925;
  background-image: none;
}
.w-btn-danger.disabled,
.w-btn-danger[disabled] {
  background-color: #eb8c95;
  z-index: 0;
}
.w-btn-danger.w-btn-basic {
  background-color: transparent !important;
  box-shadow: inset 0 0 0 #000;
  color: #dc3545;
}
.w-btn-danger.w-btn-basic:hover {
  background-color: #fceced !important;
}
.w-btn-danger.w-btn-basic:active,
.w-btn-danger.w-btn-basic.active {
  color: #dc3545;
  background-color: #f4c0c5 !important;
  background-image: none;
}
.w-btn-danger.w-btn-basic.disabled,
.w-btn-danger.w-btn-basic[disabled] {
  background-color: transparent !important;
  color: #e4606d;
}
.w-btn-light {
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.17), inset 1px -1px 0 0 rgba(0, 0, 0, 0.17), inset -1px 0px 0 0 rgba(0, 0, 0, 0.17);
  color: #393e48;
  background-color: #f8f9fa;
  z-index: 1;
}
.w-btn-light:hover {
  background-color: #dae0e5;
}
.w-btn-light:focus,
.w-btn-light.focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.26);
}
.w-btn-light:hover {
  color: #393e48;
  background-color: #dae0e5;
  z-index: 2;
}
.w-btn-light:active,
.w-btn-light.active {
  color: #393e48;
  background-color: #bcc6cf;
  background-image: none;
}
.w-btn-light.disabled,
.w-btn-light[disabled] {
  background-color: #ffffff;
  z-index: 0;
}
.w-btn-light.w-btn-basic {
  background-color: transparent !important;
  box-shadow: inset 0 0 0 #000;
  color: #f8f9fa;
}
.w-btn-light.w-btn-basic:hover {
  background-color: #ffffff !important;
}
.w-btn-light.w-btn-basic:active,
.w-btn-light.w-btn-basic.active {
  color: #f8f9fa;
  background-color: #ffffff !important;
  background-image: none;
}
.w-btn-light.w-btn-basic.disabled,
.w-btn-light.w-btn-basic[disabled] {
  background-color: transparent !important;
  color: #ffffff;
}
.w-btn-light:focus,
.w-btn-light.focus {
  outline: 0;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.17), inset 1px -1px 0 0 rgba(0, 0, 0, 0.17), inset -1px 0px 0 0 rgba(0, 0, 0, 0.17), 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.w-btn-light.w-btn-basic {
  color: #9199a7;
}
.w-btn-light.w-btn-basic:focus,
.w-btn-light.w-btn-basic.focus {
  box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.17);
}
.w-btn-light.w-btn-basic:hover {
  background-color: #ecedf0 !important;
}
.w-btn-light.w-btn-basic:active,
.w-btn-light.w-btn-basic.active {
  color: #9199a7;
  background-color: #d5d8dd !important;
  background-image: none;
}
.w-btn-light.w-btn-basic.disabled,
.w-btn-light.w-btn-basic[disabled] {
  background-color: transparent !important;
  color: #adb3be;
}
.w-btn-light.disabled,
.w-btn-light[disabled] {
  color: #666f81;
  z-index: 0;
}
.w-btn-dark {
  color: #fff;
  background-color: #393e48;
  z-index: 1;
}
.w-btn-dark:hover {
  background-color: #22252c;
}
.w-btn-dark:focus,
.w-btn-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(57, 62, 72, 0.26);
}
.w-btn-dark:hover {
  color: #fff;
  background-color: #22252c;
  z-index: 2;
}
.w-btn-dark:active,
.w-btn-dark.active {
  color: #fff;
  background-color: #0c0d0f;
  background-image: none;
}
.w-btn-dark.disabled,
.w-btn-dark[disabled] {
  background-color: #666f81;
  z-index: 0;
}
.w-btn-dark.w-btn-basic {
  background-color: transparent !important;
  box-shadow: inset 0 0 0 #000;
  color: #393e48;
}
.w-btn-dark.w-btn-basic:hover {
  background-color: #a2a8b5 !important;
}
.w-btn-dark.w-btn-basic:active,
.w-btn-dark.w-btn-basic.active {
  color: #393e48;
  background-color: #858e9f !important;
  background-image: none;
}
.w-btn-dark.w-btn-basic.disabled,
.w-btn-dark.w-btn-basic[disabled] {
  background-color: transparent !important;
  color: #505764;
}
.w-btn-link {
  color: #008ef0;
  background-color: transparent;
  z-index: 1;
  color: #008ef0 !important;
}
.w-btn-link:hover {
  background-color: rgba(0, 0, 0, 0);
}
.w-btn-link:focus,
.w-btn-link.focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0);
}
.w-btn-link:hover {
  color: #008ef0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
}
.w-btn-link:active,
.w-btn-link.active {
  color: #008ef0;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}
.w-btn-link.disabled,
.w-btn-link[disabled] {
  background-color: rgba(51, 51, 51, 0);
  z-index: 0;
}
.w-btn-link.w-btn-basic {
  background-color: transparent !important;
  box-shadow: inset 0 0 0 #000;
  color: transparent;
}
.w-btn-link.w-btn-basic:hover {
  background-color: rgba(107, 107, 107, 0) !important;
}
.w-btn-link.w-btn-basic:active,
.w-btn-link.w-btn-basic.active {
  color: transparent;
  background-color: rgba(82, 82, 82, 0) !important;
  background-image: none;
}
.w-btn-link.w-btn-basic.disabled,
.w-btn-link.w-btn-basic[disabled] {
  background-color: transparent !important;
  color: rgba(26, 26, 26, 0);
}
.w-btn-link:hover:not([disabled]) {
  color: #006ab3;
  text-decoration: underline;
}
.w-btn-link:not([disabled]):active,
.w-btn-link:not([disabled]).active {
  color: #002d4d;
  box-shadow: none;
  text-decoration: underline;
}
.w-btn-link.disabled,
.w-btn-link[disabled] {
  z-index: 0;
}
.w-btn .w-icon {
  font-size: 16px;
}
.w-btn-size-large {
  font-size: 16px;
  line-height: 16px;
  min-height: 36px;
}
.w-btn-size-large .w-icon {
  font-size: 20px;
}
.w-btn-size-small {
  padding: 0 6px;
  min-width: 20px;
  font-size: 12px;
  line-height: 24px;
  min-height: 24px;
}
.w-btn-size-small .w-icon {
  font-size: 14px;
}
.w-btn .w-icon:not(:last-child) {
  margin-right: 5px;
}
.w-btn-loading.w-btn-light::before {
  border: 1.2px solid #666f81;
}
.w-btn-loading::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 1.2px solid #fff;
  color: #fff;
  margin: 0 3px 0 0;
  -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 30%, 0% 30%);
          clip-path: polygon(0% 0%, 100% 0, 100% 30%, 0% 30%);
  animation: rotate 0.5s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
