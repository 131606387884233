.w-slider {
  width: -webkit-fill-available;
  width: stretch;
  height: 4px;
  margin: 13px 7px;
  background-color: #e4e8f1;
  border-radius: 3px;
  position: relative;
  font-size: 12px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  vertical-align: middle;
}
.w-slider-bar {
  height: 4px;
  border-radius: 3px;
  background-color: #008ef0;
  position: absolute;
}
.w-slider.disabled,
.w-slider.disabled .w-slider-mark > div {
  cursor: not-allowed;
}
.w-slider.disabled .w-slider-bar {
  background-color: #bdc2cc;
}
.w-slider.disabled .w-slider-handle {
  background-color: #e6e6e6;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0px 0px rgba(16, 22, 26, 0.2);
}
.w-slider-handle {
  left: 0%;
  width: 14px;
  height: 14px;
  position: absolute;
  z-index: 1;
  margin-top: -5px;
  transform: translateX(-50%);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2);
  background-color: #f5f8fa;
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0));
  border-radius: 50%;
  transition: all 0.3;
}
.w-slider-dots > div {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 0 0 1.6px rgba(16, 22, 26, 0.16);
  top: 0px;
  transform: translateX(-50%);
}
.w-slider:not(.disabled) .w-slider-handle:active {
  background-color: #d8e1e8;
  transition: all 0.3;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px 2px rgba(0, 0, 0, 0.1);
}
.w-slider:not(.disabled) .w-slider-handle:hover .w-slider-tooltip,
.w-slider:not(.disabled) .w-slider-handle:focus .w-slider-tooltip,
.w-slider:not(.disabled) .w-slider-handle:active .w-slider-tooltip {
  display: inline-block;
}
.w-slider-tooltip {
  display: none;
  font-size: 12px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 0px 4px;
  margin-top: -3px;
  border-radius: 3px;
  transform: translate(-50%, -100%);
  margin-left: 6px;
}
.w-slider-tooltip.open {
  display: inline-block;
}
.w-slider-with-marks {
  margin-bottom: 24px;
}
.w-slider-mark.no-marks {
  display: none;
}
.w-slider-mark > div {
  margin-bottom: -50%;
  margin-left: 3px;
  padding: 0 3px;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  transform: translate(-50%, 50%);
  color: rgba(0, 0, 0, 0.43);
}
.w-slider-vertical {
  height: 100%;
  height: -webkit-fill-available;
  height: stretch;
  width: 4px;
  margin: 7px 13px;
}
.w-slider-vertical .w-slider-dots > div {
  left: 2px;
}
.w-slider-vertical .w-slider-bar {
  width: 4px;
  height: initial;
}
.w-slider-vertical .w-slider-handle {
  margin-left: 2px;
}
.w-slider-vertical .w-slider-mark > div {
  transform: translate(0, -50%);
  padding: 0;
  margin-left: 12px;
  margin-top: 2px;
}
