.w-progress {
  position: relative;
  line-height: 1;
  display: inline-block;
}
.w-progress-text {
  color: #48576a;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  line-height: 1;
}
