.w-tabs-bar {
  position: relative;
  overflow-x: auto;
  height: calc(100% + 17px);
}
.w-tabs-nav {
  position: relative;
}
.w-tabs-nav-hidden {
  display: flex;
  overflow-y: auto;
  padding: 5px 10px 5px 5px;
  max-height: 200px;
  flex-direction: column;
}
.w-tabs-nav-hidden :hover {
  background: #d9d9d9;
}
.w-tabs-item {
  padding: 7px 10px;
  display: inline-block;
  height: 100%;
  font-size: 14px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
}
.w-tabs-item.active {
  color: #008ef0;
}
.w-tabs-item.disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
          user-select: none;
  color: rgba(0, 0, 0, 0.25);
}
.w-tabs-flow-content {
  margin-left: 5px;
  padding: 0px 10px 0px 10px;
  box-shadow: 1px 0px 0px #d9d9d9 inset;
  cursor: pointer;
}
.w-tabs-card > div > div > .w-tabs-bar > .w-tabs-nav::after,
.w-tabs-line > div > div > .w-tabs-bar > .w-tabs-nav::after {
  content: '';
  position: relative;
  display: block;
  border-bottom: 1px solid #d9d9d9;
}
.w-tabs-line > div > div > .w-tabs-bar > .w-tabs-nav > .w-tabs-slide {
  background-color: #108ee9;
  box-sizing: border-box;
  bottom: 0;
  position: absolute;
  height: 1px;
  left: 0;
  width: 20px;
  z-index: 1;
  transition: all 0.3s;
}
.w-tabs-card > div > div > .w-tabs-bar > .w-tabs-nav::after {
  content: '';
  position: relative;
  display: block;
  border-bottom: 1px solid #d9d9d9;
}
.w-tabs-card > div > div > .w-tabs-bar > .w-tabs-nav > .w-tabs-item {
  margin: 0;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  padding: 7px 16px;
  border-radius: 4px 4px 0 0;
  background: #f9f9f9;
  margin-right: 5px;
  bottom: -1px;
}
.w-tabs-card > div > div > .w-tabs-bar > .w-tabs-nav > .w-tabs-item.active {
  background: #fff;
  z-index: 1;
}
