.w-tooltip {
  position: relative;
  display: inline-block;
}
.w-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.w-tooltip-inner {
  font-size: 12px;
  max-width: 250px;
  padding: 5px 10px;
  display: block;
  color: #fff;
  text-align: left;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  word-break: break-all;
}
.w-tooltip.right .w-tooltip-arrow,
.w-tooltip.rightTop .w-tooltip-arrow,
.w-tooltip.rightBottom .w-tooltip-arrow {
  border-right-color: rgba(0, 0, 0, 0.75);
  border-width: 5px 5px 5px 0;
  left: 0;
  margin-top: -5px;
  top: 50%;
}
.w-tooltip.left .w-tooltip-arrow,
.w-tooltip.leftTop .w-tooltip-arrow,
.w-tooltip.leftBottom .w-tooltip-arrow {
  border-left-color: rgba(0, 0, 0, 0.75);
  border-width: 5px 0 5px 5px;
  margin-top: -5px;
  right: 0;
  top: 50%;
}
.w-tooltip.leftTop .w-tooltip-arrow,
.w-tooltip.rightTop .w-tooltip-arrow {
  top: 15px;
}
.w-tooltip.leftBottom .w-tooltip-arrow,
.w-tooltip.rightBottom .w-tooltip-arrow {
  bottom: 10px;
  top: auto;
}
.w-tooltip.top .w-tooltip-arrow,
.w-tooltip.topLeft .w-tooltip-arrow,
.w-tooltip.topRight .w-tooltip-arrow {
  border-top-color: rgba(0, 0, 0, 0.75);
  border-width: 5px 5px 0;
  bottom: 0;
  left: 50%;
  margin-left: -5px;
}
.w-tooltip.bottom .w-tooltip-arrow,
.w-tooltip.bottomLeft .w-tooltip-arrow,
.w-tooltip.bottomRight .w-tooltip-arrow {
  border-bottom-color: rgba(0, 0, 0, 0.75);
  border-width: 0 5px 5px;
  left: 50%;
  margin-left: -5px;
  top: 0;
}
.w-tooltip.bottomLeft .w-tooltip-arrow,
.w-tooltip.topLeft .w-tooltip-arrow {
  left: 15px;
}
.w-tooltip.bottomRight .w-tooltip-arrow,
.w-tooltip.topRight .w-tooltip-arrow {
  right: 10px;
  left: auto;
}
.w-tooltip.bottom,
.w-tooltip.bottomLeft,
.w-tooltip.bottomRight {
  padding-top: 5px;
}
.w-tooltip.top,
.w-tooltip.topLeft,
.w-tooltip.topRight {
  padding-bottom: 5px;
}
.w-tooltip.right,
.w-tooltip.rightTop,
.w-tooltip.rightBottom {
  padding-left: 5px;
}
.w-tooltip.left,
.w-tooltip.leftTop,
.w-tooltip.leftBottom {
  padding-right: 5px;
}
.w-tooltip.no-arrow {
  padding: 0 !important;
}
