.w-loader {
  position: relative;
  display: inline-block;
}
.w-loader-tips-nested svg {
  height: 100%;
  width: 100%;
  animation: loaderRotate 2s linear infinite;
  transform-origin: center center;
  fill: currentcolor;
  vertical-align: middle;
}
.w-loader-tips-nested svg circle {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: loaderDash 1.5s ease-in-out infinite, loaderColor 6s ease-in-out infinite;
  stroke-linecap: round;
}
.w-loader-large .w-loader-tips-nested svg {
  width: 30px;
  height: 30px;
}
.w-loader-large .w-loader-tips-nested svg circle {
  stroke-width: 3px;
}
.w-loader-default .w-loader-tips-nested svg {
  width: 20px;
  height: 20px;
}
.w-loader-small .w-loader-tips-nested svg {
  width: 14px;
  height: 14px;
}
.w-loader-blur {
  opacity: 0.5;
  filter: blur(0.5px);
  overflow: hidden;
}
.w-loader-warp {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.w-loader-tips-nested {
  vertical-align: middle;
  display: inline-block;
}
.w-loader-tips {
  color: #2d8cf0;
  text-align: center;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 4;
  font-weight: 400;
}
.w-loader-tips:before {
  content: ' ';
  display: inline-block;
  height: 100%;
  width: 0.1px;
  vertical-align: middle;
}
.w-loader-tips:not(:last-child) {
  position: absolute;
}
.w-loader-text {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}
.w-loader-vertical {
  display: block;
  margin-left: 0;
  margin-top: 5px;
}
.w-loader-fullscreen {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 99999;
}
@keyframes loaderRotate {
  to {
    transform: rotate(1turn);
  }
}
@keyframes loaderDash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  to {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@keyframes loaderColor {
  0%,
  to {
    stroke: currentColor;
  }
  80%,
  90% {
    stroke: currentColor;
  }
}
