.w-col {
  min-width: 0;
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}
.w-col-fixed {
  flex: 0 1 auto;
}
.w-col-align-top {
  align-self: flex-start;
}
.w-col-align-middle {
  align-self: center;
}
.w-col-align-bottom {
  align-self: flex-end;
}
.w-col-align-baseline {
  align-self: baseline;
}
.w-col-grow-1 {
  flex-grow: 1;
}
.w-col-grow-2 {
  flex-grow: 2;
}
.w-col-grow-3 {
  flex-grow: 3;
}
.w-col-grow-4 {
  flex-grow: 4;
}
.w-col-grow-5 {
  flex-grow: 5;
}
.w-col-grow-6 {
  flex-grow: 6;
}
.w-col-grow-7 {
  flex-grow: 7;
}
.w-col-grow-8 {
  flex-grow: 8;
}
.w-col-grow-9 {
  flex-grow: 9;
}
.w-col-grow-10 {
  flex-grow: 10;
}
.w-col-grow-11 {
  flex-grow: 11;
}
.w-col-1 {
  flex-basis: 4.16666667%;
  max-width: 4.16666667%;
}
.w-col-2 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}
.w-col-3 {
  flex-basis: 12.5%;
  max-width: 12.5%;
}
.w-col-4 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}
.w-col-5 {
  flex-basis: 20.83333333%;
  max-width: 20.83333333%;
}
.w-col-6 {
  flex-basis: 25%;
  max-width: 25%;
}
.w-col-7 {
  flex-basis: 29.16666667%;
  max-width: 29.16666667%;
}
.w-col-8 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}
.w-col-9 {
  flex-basis: 37.5%;
  max-width: 37.5%;
}
.w-col-10 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}
.w-col-11 {
  flex-basis: 45.83333333%;
  max-width: 45.83333333%;
}
.w-col-12 {
  flex-basis: 50%;
  max-width: 50%;
}
.w-col-13 {
  flex-basis: 54.16666667%;
  max-width: 54.16666667%;
}
.w-col-14 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}
.w-col-15 {
  flex-basis: 62.5%;
  max-width: 62.5%;
}
.w-col-16 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}
.w-col-17 {
  flex-basis: 70.83333333%;
  max-width: 70.83333333%;
}
.w-col-18 {
  flex-basis: 75%;
  max-width: 75%;
}
.w-col-19 {
  flex-basis: 79.16666667%;
  max-width: 79.16666667%;
}
.w-col-20 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}
.w-col-21 {
  flex-basis: 87.5%;
  max-width: 87.5%;
}
.w-col-22 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}
.w-col-23 {
  flex-basis: 95.83333333%;
  max-width: 95.83333333%;
}
.w-col-24 {
  flex-basis: 100%;
  max-width: 100%;
}
