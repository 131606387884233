.w-overlay.w-overlay-trigger {
  position: absolute;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  overflow: inherit;
  width: initial;
  height: initial;
}
.w-overlay.w-overlay-trigger .w-overlay-container {
  position: relative;
  overflow: inherit;
}
.w-overlay.w-overlay-trigger .w-overlay-container::before {
  display: none;
}
.w-overlay.w-overlay-trigger .w-overlay-content {
  margin: 0;
}
.w-overlay.w-overlay-trigger.rightTop .w-overlay-content {
  transform-origin: left top;
}
.w-overlay.w-overlay-trigger.right .w-overlay-content {
  transform-origin: left center;
}
.w-overlay.w-overlay-trigger.rightBottom .w-overlay-content {
  transform-origin: left bottom;
}
.w-overlay.w-overlay-trigger.topLeft .w-overlay-content {
  transform-origin: bottom left;
}
.w-overlay.w-overlay-trigger.top .w-overlay-content {
  transform-origin: bottom center;
}
.w-overlay.w-overlay-trigger.topRight .w-overlay-content {
  transform-origin: bottom right;
}
.w-overlay.w-overlay-trigger.leftTop .w-overlay-content {
  transform-origin: right top;
}
.w-overlay.w-overlay-trigger.left .w-overlay-content {
  transform-origin: right center;
}
.w-overlay.w-overlay-trigger.leftBottom .w-overlay-content {
  transform-origin: right bottom;
}
.w-overlay.w-overlay-trigger.bottomLeft .w-overlay-content {
  transform-origin: top left;
}
.w-overlay.w-overlay-trigger.bottom .w-overlay-content {
  transform-origin: top center;
}
.w-overlay.w-overlay-trigger.bottomRight .w-overlay-content {
  transform-origin: top right;
}
.w-overlay-trigger-disabled {
  cursor: not-allowed;
}
