body {
  background-color: #fff;
  font-family: Helvetica Neue For Number, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  background: #f0f2f5;
}

a {
  color: #314659;
  text-decoration: none;
  transition: color 0.3s;
  &:hover {
    color: #1890ff;
  }
}

:global {
  #root {
    height: 100%;
  }
}
