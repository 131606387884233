.w-dateinput {
  display: inline-block;
}
.w-dateinput-popover {
  box-shadow: 0 0 0 0;
}
.w-dateinput.disabled::before {
  cursor: not-allowed;
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.w-dateinput-close-btn {
  display: flex;
  margin: 0 3px;
  color: #a5a5a5;
  cursor: pointer;
}
.w-dateinput-close-btn:hover {
  color: #393e48;
}
