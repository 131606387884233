.w-timepicker {
  display: flex;
}
.w-timepicker::after {
  content: '';
  display: block;
  clear: both;
}
.w-timepicker-spinner {
  max-height: 189px;
  flex: 1;
  overflow: auto;
  font-size: 14px;
}
.w-timepicker-spinner:hover {
  overflow: auto;
}
.w-timepicker-spinner > ul {
  list-style: none;
  min-width: 56px;
  margin: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 162px !important;
}
.w-timepicker-spinner > ul > li + li {
  margin-top: 0 !important;
}
.w-timepicker-spinner > ul > li {
  cursor: pointer;
  text-align: center;
  height: 28px;
  line-height: 28px;
  transition: all 0.3s;
  color: #393e48;
}
.w-timepicker-spinner > ul > li:hover:not(.disabled) {
  background-color: #f6f8fa;
}
.w-timepicker-spinner > ul > li:active:not(.disabled) {
  background-color: #e1e5e8;
}
.w-timepicker-spinner > ul > li.selected {
  background-color: #f1f1f1;
  font-weight: bold;
  color: #000;
}
.w-timepicker-spinner > ul > li.disabled {
  color: #d4d4d4;
  cursor: not-allowed;
}
.w-timepicker-spinner > ul > li.disabled.hide {
  display: none;
}
.w-timepicker-spinner + .w-timepicker-spinner ul {
  border-left: 1px solid #e9e9e9;
}
