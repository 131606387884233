.w-menu {
  margin: 0 !important;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 3px;
  min-width: 100%;
  width: 100%;
  text-align: left;
  font-size: 14px;
  transition: width 0.2s, min-width 0.2s, max-width 0.2s, height 0.2s, min-height 0.2s, max-height 0.2s;
}
.w-menu.w-menu-dark,
.w-menu.w-menu-light {
  padding-left: 5px;
}
.w-menu:not(.w-menu-dark) {
  color: #182026;
  background: #fff;
}
.w-menu li {
  margin: 0 !important;
  list-style: none !important;
  position: relative;
}
.w-menu li:first-of-type {
  border-top: none;
}
.w-menu li a:not([href]) {
  color: inherit;
}
.w-menu.w-bordered {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
}
.w-menu .w-menu-divider {
  display: block;
  margin: 5px !important;
  border-top: 1px solid rgba(16, 22, 26, 0.15);
}
.w-menu .w-menu-divider > strong {
  display: block;
  line-height: 17px;
}
.w-menu .w-menu-divider:not(:first-of-type) > strong {
  margin-top: 10px;
}
.w-menu.w-menu-light.w-menu-inline-collapsed > li > .w-menu-item:hover {
  color: #008ef0;
}
.w-menu-inline-collapsed {
  width: 80px;
  min-width: 80px;
}
.w-menu-inline-collapsed > li > .w-menu-item {
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
}
.w-menu-inline-collapsed > li > .w-menu-item:hover {
  background-color: transparent;
  color: #fff;
}
.w-menu-inline-collapsed > li > .w-menu-item > * {
  margin-right: 0;
}
.w-menu-inline-collapsed > li > .w-menu-item .w-menu-item-text {
  width: 0;
  flex-grow: 0;
}
.w-menu-inline-collapsed > li > .w-menu-item .w-menu-subitem-collapse-icon {
  display: none;
}
.w-menu-dark,
.w-menu-dark.w-bordered,
.w-menu-dark .w-menu {
  background: #343a40;
  color: rgba(255, 255, 255, 0.65);
}
.w-menu-dark.w-bordered {
  box-shadow: initial;
}
.w-menu-dark .active {
  color: #fff !important;
}
.w-menu-dark .w-menu-item.w-disabled,
.w-menu-dark .w-menu-item.w-disabled .w-menu-item-icon {
  color: rgba(142, 142, 142, 0.79) !important;
}
.w-menu-dark .w-menu-divider {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
