.w-overlay {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  left: 0;
  outline: 0;
  bottom: 0;
  z-index: 999;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  height: 100%;
  width: 100%;
  display: none;
}
.w-overlay.open {
  z-index: 9999;
}
.w-overlay-open {
  overflow: hidden;
}
.w-overlay-content {
  position: relative;
  outline: 0;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  margin: 20px 0;
  z-index: 20;
}
.w-overlay-inline {
  position: absolute;
  overflow: initial;
}
.w-overlay-inline .w-overlay-container {
  position: relative;
}
.w-overlay-inline .w-overlay-backdrop {
  position: absolute;
}
.w-overlay-container {
  position: absolute;
  overflow: auto;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.w-overlay-container:before {
  content: ' ';
  display: inline-block;
  height: 100%;
  width: 1px;
  vertical-align: middle;
}
.w-overlay-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: 20;
  background-color: rgba(16, 22, 26, 0.7);
  overflow: auto;
  -webkit-user-select: none;
          user-select: none;
}
.w-overlay-enter .w-overlay-backdrop {
  opacity: 0;
}
.w-overlay-enter-active .w-overlay-backdrop {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.w-overlay-exit .w-overlay-backdrop {
  opacity: 1;
}
.w-overlay-exit-active .w-overlay-backdrop {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
.w-overlay-enter .w-overlay-content {
  transform: scale(0.5);
  opacity: 0;
}
.w-overlay-enter-active .w-overlay-content {
  opacity: 1;
  transform: translate(0);
  transition: transform 300ms ease, opacity 300ms ease;
}
.w-overlay-exit .w-overlay-content {
  opacity: 1;
  transform: translate(0);
  transition: transform 300ms ease, opacity 300ms ease;
}
.w-overlay-exit-active .w-overlay-content {
  transform: scale(0.5);
  opacity: 0;
}
.w-overlay-enter,
.w-overlay-enter-done,
.w-overlay-exit {
  display: inherit;
}
