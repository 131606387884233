.w-menu-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 2px;
  padding: 5px 7px;
  text-decoration: none;
  line-height: 20px;
  color: inherit;
  -webkit-user-select: none;
          user-select: none;
  transition: initial;
  transition: background-color 0.3s;
}
.w-menu-item:hover,
.w-menu-item.active {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  background-color: rgba(167, 182, 194, 0.3);
}
.w-menu-item:active {
  background-color: rgba(115, 134, 148, 0.3);
}
.w-menu-item.w-disabled {
  outline: none !important;
  background-color: inherit !important;
  cursor: not-allowed !important;
}
.w-menu-item.w-disabled .w-menu-item-icon,
.w-menu-item.w-disabled {
  color: rgba(92, 112, 128, 0.5) !important;
}
.w-menu-item-text {
  flex-grow: 1;
}
.w-menu-item > * {
  margin-right: 7px;
}
.w-menu-item > :last-child {
  margin-right: 0;
}
.w-menu-item-multiline {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}
