.w-progress-circle .w-progress-text {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  transform: translate(0, -50%);
  font-size: 16px;
}
.w-progress-trail {
  stroke: #e5e9f2;
}
.w-progress-stroke {
  stroke: #20a0ff;
}
.w-progress-status-success .w-progress-stroke {
  stroke: #28a745;
}
.w-progress-status-exception .w-progress-stroke {
  stroke: #ff4949;
}
