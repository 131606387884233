.w-empty {
  color: #c7c7c7;
  text-align: center;
}
.w-empty-description {
  margin-top: 10px;
}
.w-empty-footer {
  margin-top: 16px;
}
