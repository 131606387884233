.w-textarea {
  position: relative;
  font-size: 14px;
  outline: none;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  background: #fff;
  padding: 5px 10px;
  vertical-align: middle;
  height: auto;
  min-height: 30px;
  color: #393e48;
  font-weight: 400;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  transition: box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-appearance: none;
          appearance: none;
}
.w-textarea:not(:first-child) {
  padding-left: 30px;
}
.w-textarea:focus {
  box-shadow: 0 0 0 1px #393e48, 0 0 0 3px rgba(57, 62, 72, 0.17), inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
.w-textarea:hover {
  box-shadow: 0 0 0 1px #6e6e6e, 0 0 0 3px rgba(57, 62, 72, 0), inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
.w-textarea:focus.w-textarea:hover {
  box-shadow: 0 0 0 1px #6e6e6e, 0 0 0 3px rgba(57, 62, 72, 0.17), inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
.w-textarea:disabled {
  box-shadow: none;
  background: #dddddd;
  opacity: 0.75;
  color: #a5a5a5;
  cursor: not-allowed;
  resize: none;
}
