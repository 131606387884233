.w-tag {
  border-radius: 2px;
  display: inline-block;
  -webkit-user-select: none;
          user-select: none;
  font-size: 12px;
  padding: 1px 5px;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
}
.w-tag + .w-tag {
  margin-left: 5px;
}
.w-tag.disabled {
  opacity: 0.75;
  cursor: not-allowed;
}
.w-tag-light .w-tag-close:hover {
  background-color: currentcolor;
}
.w-tag-light .w-tag-close:hover path {
  fill: #fff;
}
.w-tag-light .w-tag-close:active {
  background-color: rgba(0, 0, 0, 0.15);
}
.w-tag-close {
  vertical-align: bottom;
  margin: 0px -3px 0 2px;
  border-radius: 2px;
  position: relative;
  top: -1px;
  fill: currentcolor;
  transition: all 0.3s;
  cursor: pointer;
}
.w-tag-close:hover {
  background-color: rgba(0, 0, 0, 0.22);
}
.w-tag-close:active {
  background-color: rgba(0, 0, 0, 0.32);
}
