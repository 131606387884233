.w-icon,
.w-icon svg {
  height: 1em;
  width: 1em;
}
.w-icon {
  display: inline-flex;
  align-self: center;
  position: relative;
  transition: color 0.3s;
  box-sizing: inherit;
}
.w-icon-baseline {
  top: 0.125em;
}
.w-icon-spin svg {
  animation: loading-circle 1s infinite linear;
}
@keyframes loading-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
