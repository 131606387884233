.w-table {
  width: 100%;
  overflow: auto;
}
.w-table > table {
  display: table !important;
  margin: 0 !important;
  width: 100% !important;
  border-spacing: 0;
  border-collapse: collapse;
}
.w-table > table tr > th,
.w-table > table tr > td {
  border: 0;
  padding: 5px 8px;
  border-bottom: 1px solid #e8e8e8;
}
.w-table > table tr {
  background-color: transparent;
}
.w-table > table > caption {
  text-align: left;
  padding: 10px 8px;
}
.w-table > table > tbody > tr {
  transition: all 0.3s;
}
.w-table > table > tbody > tr > td {
  background-color: #fff;
  position: relative;
  z-index: 1;
}
.w-table > table > tbody > tr:hover > td,
.w-table > table > tbody > tr:hover:nth-child(2n) > td {
  background-color: #efefef;
}
.w-table > table > tbody > tr:nth-child(2n) > td {
  background-color: #f9f9f9;
}
.w-table > table > thead > tr > th {
  font-weight: normal;
  padding: 8px;
  background-color: #f6f9fb;
  position: relative;
  z-index: 1;
}
.w-table > table > thead > tr,
.w-table > table > thead tr:nth-child(2n) {
  background-color: #f6f9fb;
  border: transparent;
}
.w-table-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
  display: block;
}
.w-table-tr-children-center {
  text-align: center;
}
.w-table-tr-children-left {
  text-align: left;
}
.w-table-tr-children-right {
  text-align: right;
}
.w-table-fixed-true {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 2 !important;
}
.w-table-fixed-right {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 2 !important;
}
.w-table-fixed-true::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.w-table-fixed-true::before {
  content: '';
  position: absolute;
  left: -1px;
  top: 0;
  bottom: 0;
  border-left: 1px solid #dfe2e5;
}
.w-table-fixed-right::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
  border-right: 1px solid #f0f0f0;
}
.w-table-fixed-right-first::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.w-table-fixed-right::before {
  content: '';
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
  border-right: 1px solid #dfe2e5;
}
.w-table-bordered > table tr > th,
.w-table-bordered > table tr > td,
.w-table-bordered > table > caption {
  border: 1px solid #dfe2e5;
}
.w-table-bordered > table > caption {
  border-bottom: 0;
}
.w-table-bordered .w-table-footer {
  border: 1px solid #dfe2e5;
  border-top: 0;
}
.w-table-footer {
  background: #fafafa;
  padding: 10px 8px;
}
