.w-rate {
  position: relative;
  line-height: 12px;
  font-size: 20px;
  font-family: auto;
  display: inline-block;
  vertical-align: middle;
}
.w-rate > span + span {
  margin-left: 3px;
}
.w-rate-bg {
  color: #e9e9e9;
}
.w-rate > span,
.w-rate-bg {
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.w-rate-hight {
  z-index: 3;
  line-height: 12px;
  position: absolute;
  transition: color 0.3s, width 0.3s;
  color: #e9e9e9;
  display: none;
}
.w-rate .hover-on {
  cursor: pointer;
}
.w-rate .star-on,
.w-rate .hover-on,
.w-rate .half-on {
  display: inline-block;
  color: #f5a623;
}
.w-rate .half-on:not(.hover-on) {
  overflow: hidden;
  width: 50%;
}
