.w-search-select-input-contents input {
  box-shadow: none;
  padding: 0px;
  height: 28px;
}
.w-search-select-input-contents.w-input-small {
  min-width: 0;
}
.w-search-select-input-contents .w-input-inner:hover {
  box-shadow: none !important;
}
.w-search-select-input-contents .w-input-inner:focus {
  box-shadow: none !important;
}
.w-search-select-input-contents .w-input-inner.disabled {
  box-shadow: none;
  background: #dddddd;
  opacity: 0.75;
  color: #a5a5a5;
  cursor: not-allowed;
  resize: none;
}
.w-search-select-inner {
  display: flex;
  justify-content: space-between;
  outline: none;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-sizing: border-box;
  background: #fff;
  margin: 0 !important;
  padding: 1px 1px;
  vertical-align: middle;
  line-height: 30px;
  align-items: center;
  color: #393e48;
  font-weight: 400;
  font-size: inherit;
  transition: box-shadow 0.3s cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-appearance: none;
          appearance: none;
}
.w-search-select-inner:focus {
  box-shadow: 0 0 0 1px #393e48, 0 0 0 3px rgba(57, 62, 72, 0.17), inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
.w-search-select-inner:hover {
  box-shadow: 0 0 0 1px #6e6e6e, 0 0 0 3px rgba(57, 62, 72, 0), inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
.w-search-select-inner:focus.w-search-select-inner:hover {
  box-shadow: 0 0 0 1px #6e6e6e, 0 0 0 3px rgba(57, 62, 72, 0.17), inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
.w-search-select-inner:disabled {
  box-shadow: none;
  background: #dddddd;
  opacity: 0.75;
  color: #a5a5a5;
  cursor: not-allowed;
  resize: none;
}
.w-search-select-inner-inner-small {
  height: 20px;
}
.w-search-select-inner-inner-large {
  height: 30px;
}
.w-search-select-inner .w-input-small .w-input-inner {
  height: 16px;
  font-size: 10px;
  padding: 0;
}
.w-search-select-inner .w-input-large .w-input-inner {
  height: 28px;
}
.w-search-select-tag-content {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  width: 100%;
  padding: 0 9px;
}
.w-search-select-tag-content-disabled {
  background: #dddddd;
}
.w-search-select-search-false {
  cursor: pointer;
}
.w-search-select-multiple-colse {
  right: 3px;
  font-size: 15px;
}
.w-search-select-singe-colse {
  font-size: 15px;
}
