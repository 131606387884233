.w-list {
  font-size: 14px;
  line-height: 1.5;
  color: #52575c;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  background-color: #fff;
  transition: all 0.3s;
}
.w-list:not(.w-list-no-hover):hover,
.w-list-active {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.2);
}
.w-list-bordered {
  border: 1px solid #e9e9e9;
  border-radius: 4px;
}
.w-list-bordered .w-list-item:last-child {
  border-bottom: 0;
  border-radius: 0 0 4px 4px;
}
.w-list-item {
  display: flex;
  align-items: center;
}
.w-list-item.w-disabled {
  cursor: not-allowed;
  text-decoration: none;
  pointer-events: none;
  color: #a3a6a9;
}
.w-list-item.w-active {
  background: #f8f8f9;
  color: #007bff;
}
.w-list-item-main {
  display: block;
  flex: 1;
}
.w-list-item-extra {
  margin-left: 40px;
}
a.w-list-item {
  display: block;
  color: #52575c;
}
a.w-list-item:hover:not(.w-disabled) {
  background: #f8f8f9;
}
.w-list-striped .w-list-item:nth-of-type(2n) {
  background: #f8f8f9;
}
.w-list-bordered .w-list-item,
.w-list-bordered .w-list-header {
  border-bottom: 1px solid #e8e8e8;
}
.w-list-item,
.w-list-footer,
.w-list-header {
  padding: 12px 18px;
}
.w-list-size-small .w-list-item,
.w-list-size-small .w-list-footer,
.w-list-size-small .w-list-header {
  padding: 8px 16px;
}
.w-list-size-large .w-list-item,
.w-list-size-large .w-list-footer,
.w-list-size-large .w-list-header {
  padding: 16px 24px;
}
