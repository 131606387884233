.w-datepicker {
  vertical-align: top;
  display: inline-block;
  position: relative;
  border-radius: 3px;
  background: #fff;
  color: #393e48;
  -webkit-user-select: none;
          user-select: none;
  font-size: 14px;
  line-height: 21px;
  padding: 5px;
  min-width: 192px;
  max-width: 192px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
}
.w-datepicker-timepicker {
  border: 1px solid #e9e9e9;
  border-top: 0;
  border-radius: 0 0 4px 4px !important;
}
.w-datepicker-timepicker > div {
  max-height: 186px;
}
.w-datepicker-time-btn {
  cursor: pointer;
  margin-top: 2px;
  transition: background-color 0.3s;
  display: inline-block;
  border-radius: 3px;
  padding: 1px 5px;
}
.w-datepicker-time-btn:hover {
  background-color: #eaeaea;
}
.w-datepicker-time-btn:active {
  background-color: #d2d2d2;
}
